import React from "react"
import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core"

import BulletItem from "../components/bullet-item"
import Article from "../components/article"
import Navigation from "../components/navigation"
import Carousel from "../components/carousel"
import NumericList from "../components/numeric-list"
import Section from "../components/section"
import HeroSection from "../components/hero-section"
import Testimonial from "../components/testimonial"
import HeroImage from "./assets/POTENCIAL_20200315_171641-2048x969.jpg"
import IvoneImage from "./assets/pexels-andre-furtado-5180962.jpg"
import ListCard from "../components/list-card"
import AboutImage from "./assets/exnd-GNMHQOe0yRs-unsplash-1365x2048.jpg"
import About from "../components/about"
import CtaButton from "../components/cta-button"
import Card from "../components/card"
import { BsFillChatSquareQuoteFill } from "react-icons/bs"
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"
import Footer from "../components/footer"

export default function KT() {
  const numericListText = [
    <p>
      <strong>Zigzag numérico</strong> dolor sit amet, consetetur sadipscing elitr,
      sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
      erat, sed diam voluptua. At vero eos et{" "}
      <i className="turkis-blue1 bolder">accusam</i> et justo duo dolores et ea
      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
      dolor sit amet.
    </p>,
    "Texto Zigzag1  sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    "Texto Zigzag2  dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    "Texto Zigzag3  dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  ]
  const textList = [
    "Items de Tarjetas Verticales",
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
  ]
  const icon = <BsFillChatSquareQuoteFill size="40px" />
  const bulletIcon1 = <IoIosArrowForward size="36px" />
  const bulletIcon2 = <IoIosArrowForward size="20px" />
  return (
    <>
      <Navigation />
      <HeroSection
        bgImage={HeroImage}
        overlay="full"
        h1="The big title is here"
        titleClassName="white"
      ></HeroSection>
      
      <Section color="blue1">
        <Article className="font-size large">
          Esto es la seccion debajo del HEROImage
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet.
          <CtaButton label="Normal Button" />
          <br />
          <CtaButton label="Disabled Button" disabled />
        </Article>
      </Section>


      <Section color="beige1">
        <Article layout="narrow">
          <NumericList text={numericListText} />
        </Article>
      </Section>

      <Section color="beige1">
        <Article layout="narrow">
          <Carousel dots arrows slidesToShow={1} slidesToScroll={1} fade>
            <Testimonial name="Folan">
            <strong>Quotes-Testimonio</strong>  consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet.
            </Testimonial>
            <Testimonial name="Folan">
            <strong>Quotes-Testimonio</strong> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet.
            </Testimonial>
            <Testimonial name="Folan">
            <strong>Quotes-Testimonio</strong> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet.
            </Testimonial>
          </Carousel>
        </Article>
      </Section>


      <Section color="beige1">
        <Article layout="wide">
          <Carousel dots arrows slidesToShow={2} slidesToScroll={1}>
            <Testimonial name="Folan" variation="small">
            <strong>Testimonio-2x</strong> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </Testimonial>
            <Testimonial name="Folan" variation="small">
            <strong>Testimonio-2x</strong> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </Testimonial>
            <Testimonial name="Folan" variation="small">
            <strong>Testimonio-2x</strong> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </Testimonial>
            <Testimonial name="Folan" variation="small">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </Testimonial>
          </Carousel>
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="narrow">
          <ListCard title="Tarjetas Verticales" text={textList} image={IvoneImage} />
          <ListCard title="Tarjetas Verticales" text={textList} image={IvoneImage} />
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="wide">
          <About title="Ivone Herrera P" image={AboutImage}>
          <strong>seccion-Sobre-mi</strong> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet.
          </About>
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="wide" title="Some title">
          <Grid container spacing={1}>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </BulletItem>
            </Grid>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </BulletItem>
            </Grid>
          </Grid>
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="wide" title="Some title">
          <Grid container spacing={3} justify="center">
            <Grid item md={3}>
              <Card icon={icon} title="Title1" className="center">
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card icon={icon} title="Title1" className="center">
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card icon={icon} title="Title1" className="center">
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card icon={icon} title="Title1" className="center">
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>


      <Section color="beige1">
        <Article layout="narrow" title="text on square">
          <Card>
            <BulletItem icon={bulletIcon1}>
              text on square1 Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
            text on square2 Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
            text on square3 Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </BulletItem>
          </Card>
        </Article>
      </Section>


      <Section color="beige1">
        <Article layout="narrow" title="FAQ">
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              Accordion 2
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              Accordion 2
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              Accordion 2
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="narrow" title="Testimonial-#3">
          <Testimonial name="Folan" variation="small" image={IvoneImage}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Testimonial>
          <Testimonial name="Folan" variation="small" image={IvoneImage}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Testimonial>
          <Testimonial name="Folan" variation="small" image={IvoneImage}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Testimonial>
        </Article>
      </Section>

      <Section color="beige1">
        <Article layout="narrow" title="Card with image">
          <Grid container spacing={3} justify="center">
            <Grid item md={3}>
              <Card avatar={AboutImage} title="Title1" className="center">
                text text text text text text
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="narrow" title="Card with price tag">
          <Grid container spacing={3} justify="center">
            <Grid item md={5}>
              <Card title="Title1" className="center">
                <div className="price">
                  <div>
                    2000€
                    <br />
                    Some text
                  </div>
                </div>
                text text text text text text
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>
      <Footer />
    </>
  )
}

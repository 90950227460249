import Card, { CardProps } from "./card"
import React from "react"
import "./numeric-list.css"

type TextElement = string | React.ReactElement

interface Props extends CardProps {
  text: TextElement[]
}

const NumericList: React.FC<Props> = ({ text = [], ...rest }) => (
  <div className="numeric-list-container">
    <ul className="numeric-list">
      {text.map((item, index) => (
        <li key={`${index}${item}`} className="numeric-list-item">
          <Card className="numeric-list-card" {...rest}>
            <span className="number">{index + 1}</span>
            {typeof item === "string" ? <p>{item}</p> : item}
          </Card>
        </li>
      ))}
    </ul>
  </div>
)

export default NumericList
